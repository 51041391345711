import React from 'react'
import { BsFillEaselFill } from 'react-icons/bs'

const HelperText: React.FC<{ show: boolean }> = ({ show }) => {
	return (
		<div
			className={`helper-text-container fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-500 ${
				show ? 'opacity-100' : 'opacity-0'
			}`}
		>
			<div className="text-center">
				<div className="icon mb-2 text-center">
					<BsFillEaselFill size={50} className="text-white mx-auto" />
				</div>
				<div className="text-xl font-bold text-white helper-text">Tap on the easel to begin</div>
			</div>
		</div>
	)
}

export default HelperText
