import React, { createContext, useState, useContext } from 'react'
import { ModalType } from './types'
import * as THREE from 'three'

interface TargetContextType {
	currentModalType: ModalType | null
	setCurrentModalType: React.Dispatch<React.SetStateAction<ModalType | null>>
	modalToPosition: { [key in ModalType]: THREE.Vector3 } 
	setModalToPosition: React.Dispatch<React.SetStateAction< {[key in ModalType]: THREE.Vector3 } >>
}

const TargetContext = createContext<TargetContextType | undefined>(undefined)

export const TargetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initialModalToPosition = Object.keys(ModalType).reduce((acc, key) => {
        const modalType = key as ModalType
        acc[modalType] = new THREE.Vector3(0, 0, 0)
        return acc
      }, {} as { [key in ModalType]: THREE.Vector3 })

	const [currentModalType, setCurrentModalType] = useState<ModalType | null>(null)
	const [modalToPosition, setModalToPosition] = useState<{ [key in ModalType]: THREE.Vector3 }> (initialModalToPosition)

	return (
		<TargetContext.Provider value={{ currentModalType, setCurrentModalType, modalToPosition, setModalToPosition }}>
			{children}
		</TargetContext.Provider>
	)
}

export const useTarget = (): TargetContextType => {
	const context = useContext(TargetContext)
	if (!context) {
		throw new Error('useTarget must be used within a TargetProvider')
	}
	return context
}
