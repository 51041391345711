import React, { useState } from 'react';
import { FaAngleDown, FaFilter, FaCheck } from 'react-icons/fa';

interface TagFilterProps {
    uniqueTags: string[];
    selectedTags: string[];
    onTagClick: (tag: string) => void;
    onClearTags: () => void;
    sortFunctions: { [key: string]: () => void };
}

const TagFilter: React.FC<TagFilterProps> = ({ uniqueTags, selectedTags, onTagClick, onClearTags, sortFunctions }) => {
    const [showAllTags, setShowAllTags] = useState<boolean>(false);
    const [selectedSort, setSelectedSort] = useState<string | null>(null);

    const handleSortClick = (key: string) => {
        if (selectedSort === key) {
            setSelectedSort(null); // Deselect sort
            onClearTags();
        } else {
            setSelectedSort(key);
            sortFunctions[key]();
        }
    };

    const sortedTags = uniqueTags.sort((a, b) => (a === 'Featured' ? -1 : b === 'Featured' ? 1 : 0));
    const cutTags = sortedTags.slice(0, 3);
    
    return (
        <div className="tag-filter mb-2 relative flex items-center">
            <div className="dropdown -mt-2">
                <label tabIndex={0} className="btn btn-outline lg:btn-xs sm:btn-lg mr-2 flex items-center">
                    <FaFilter className="mr-1" /> Sort
                </label>
                <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-20">
                    {Object.keys(sortFunctions).map((key) => (
                        <li key={key}>
                            <a onClick={() => handleSortClick(key)} className="flex items-center">
                                {key}
                                {selectedSort === key && <FaCheck className="ml-2" />}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tags flex flex-wrap items-center">
                {cutTags.map((tag, index) => (
                    <button
                        key={index}
                        className={`btn btn-xs mr-2 mb-2 ${selectedTags.includes(tag) ? 'btn-primary' : 'btn-outline'}`}
                        onClick={() => onTagClick(tag)}
                    >
                        {tag}
                    </button>
                ))}
                <div className="relative">
                    <button className="btn btn-xs mr-2 mb-2" onClick={() => setShowAllTags(!showAllTags)}>
                        All <FaAngleDown />
                    </button>
                    {showAllTags && (
                        <div className="all-tags-dropdown absolute left-0 mt-2 w-64 bg-base-100 p-4 shadow-lg rounded-lg z-10">
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="text-sm font-bold">
                                    SELECT TAGS <span>({selectedTags.length})</span>
                                </h4>
                                <button className="text-sm text-blue-500 hover:underline" onClick={onClearTags}>
                                    Clear all
                                </button>
                            </div>
                            <div className="tags flex flex-wrap">
                                {sortedTags.map((tag, index) => (
                                    <button
                                        key={index}
                                        className={`btn btn-xs mr-2 mb-2 ${selectedTags.includes(tag) ? 'btn-primary' : 'btn-outline'}`}
                                        onClick={() => onTagClick(tag)}
                                    >
                                        {tag}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TagFilter;
