import { Clouds, Cloud } from '@react-three/drei'
import * as THREE from 'three'

export default function CloudRender() {
	return (
		<group>
			<Clouds material={THREE.MeshBasicMaterial} limit={200}>
				<Cloud
					speed={0.1}
					growth={4}
					volume={6}
					segments={20}
					opacity={0.8}
					fade={10}
					bounds={[6, 1, 1]}
					color="#eed0d0"
					seed={1}
					position={[40, 0, 0]}
				/>
				<Cloud
					speed={0.1}
					growth={4}
					volume={6}
					segments={20}
					opacity={0.8}
					fade={10}
					bounds={[6, 1, 1]}
					color="#d0e0d0"
					seed={1}
					position={[-40, -10, 0]}
				/>
				<Cloud
					speed={0.1}
					growth={4}
					volume={6}
					segments={20}
					opacity={0.8}
					fade={10}
					bounds={[6, 1, 1]}
					color="#eed0d0"
					seed={1}
					position={[0, 10, 30]}
				/>
				<Cloud
					speed={0.1}
					growth={4}
					volume={6}
					segments={20}
					opacity={0.8}
					fade={10}
					bounds={[6, 1, 1]}
					color="#d0e0d0"
					seed={1}
					position={[0, 20, -30]}
				/>
			</Clouds>
		</group>
	)
}
