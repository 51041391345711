import React from 'react'
import { ParagraphContent, Tag, TagType } from '../../types'
import * as FaIcons from 'react-icons/fa'
import parse from 'html-react-parser'

const ContentSection: React.FC<{ paragraph: ParagraphContent }> = ({ paragraph }) => {
	const renderTags = (tags?: Tag[]) => {
		return tags?.slice(0, 3).map((tag, index) => {
			let tagColor = 'badge-neutral'
			switch (tag.type) {
				case TagType.Featured:
					tagColor = 'badge-accent'
					break
				case TagType.Education:
					tagColor = 'badge-primary'
					break
				default:
					break
			}
			return (
				<div className={`badge ${tagColor} mr-2 mb-2 text-xs flex items-center`} key={index}>
					{tag.type === TagType.Featured && <FaIcons.FaStar className="mr-1" />}
					{tag.text}
				</div>
			)
		})
	}

	const renderLinks = (href?: { url: string; text: string; icon?: string }[]) => {
		return href?.map((link, index) => (
			<a key={index} href={link.url} className="text-sm text-blue-500 hover:underline flex items-center -mt-2 mr-4">
				{link.icon ? React.createElement(FaIcons[link.icon as keyof typeof FaIcons], { size: 15 }) : null}
				<span className="ml-2">{link.text}</span>
			</a>
		))
	}

	const renderContent = () => {
		switch (paragraph.type) {
			case 'showcase':
				return (
					<div className="showcase-content p-4 bg-neutral-content rounded-lg shadow-sm flex flex-col md:flex-row gap-4 mb-4">
						{paragraph.image && (
							<div className="showcase-image md:w-1/3 w-full">
								<img src={paragraph.image} alt={paragraph.title} className="rounded-lg w-full h-auto" />
							</div>
						)}
						<div className="showcase-text md:w-2/3 w-full flex flex-col justify-between">
							<div>
								<h3 className="font-bold text-xl mb-2">{paragraph.title}</h3>
								<p className="text-base mb-2">{paragraph.description}</p>
								{paragraph.extraDescription && (
									<p className="text-sm text-gray-600 mb-2">{paragraph.extraDescription}</p>
								)}
								<div className="tags mb-2 flex flex-wrap">{renderTags(paragraph.tags)}</div>
							</div>
							<div className="links mt-4 flex flex-wrap">{renderLinks(paragraph.href)}</div>
						</div>
					</div>
				)
			case 'description':
				return (
					<div>
						<p className="py-2 text-base">{parse(paragraph.description)}</p>
						{paragraph.items?.map((item, index) => {
							return (
								<div key={index} className="flex flex-col mt-2">
									<h4 className="font-bold text-lg">{item.title}</h4>
									<p className="text-base">{item.description}</p>
								</div>
							)
						})}
					</div>
				)
			case 'collapse':
				return (
					<div className="collapse collapse-arrow border border-base-300 bg-base-200 mb-3">
						<input type="checkbox" className="peer" />
						<div className="collapse-title text-xl font-medium peer-checked:bg-base-300">
							{paragraph.title}
							<div className="tags mt-2 flex flex-wrap">{renderTags(paragraph.tags)}</div>
						</div>

						<div className="collapse-content peer-checked:block">
							<div className="showcase-content p-4 flex flex-col md:flex-row gap-4 mb-4">
								{paragraph.image && (
									<div className="showcase-image md:w-1/3 w-full">
										<img src={paragraph.image} alt={paragraph.title} className="rounded-lg w-full h-auto" />
									</div>
								)}
								<div className="showcase-text md:w-2/3 w-full flex flex-col justify-between">
									<div>
										<p className="text-base mb-2">{paragraph.description}</p>
										{paragraph.extraDescription && (
											<p className="text-sm text-gray-600 mb-2">{paragraph.extraDescription}</p>
										)}
									</div>
									<div className="links mt-4 flex flex-wrap">{renderLinks(paragraph.href)}</div>
								</div>
							</div>
						</div>
					</div>
				)
			case 'timeline':
				return (
					<ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
						{paragraph.items?.map((item, index) => {
							const left = index % 2 === 0
							return (
								<li key={index}>
									<hr />
									<div className="timeline-middle">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
											<path
												fillRule="evenodd"
												d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
												clipRule="evenodd"
											/>
										</svg>
									</div>
									<div key={index} className={` ${left ? 'timeline-start md:text-end' : 'timeline-end'} mb-10`}>
										<div className="timeline-text">
											<h4 className="text-xl font-bold mb-2">{item.title}</h4>
											<p className="text-base mb-2">{item.description}</p>
											{item.extraDescription && <p className="text-sm text-gray-600 mb-2">{item.extraDescription}</p>}
											<div className={`tags mb-2 flex flex-wrap ${left ? 'justify-end' : 'justify-start'}`}>
												{renderTags(item.tags)}
											</div>
											<div className={`links mt-4 flex flex-wrap ${left ? 'justify-end' : 'justify-start'}`}>
												{renderLinks(item.href)}
											</div>
										</div>
									</div>
									<hr />
								</li>
							)
						})}
					</ul>
				)
			case 'carousel':
				return (
					<ul className="menu menu-horizontal">
						{paragraph.items?.map((item, _) => {
							if (!item.href) return null
							const currHref = item.href[0]!
							return (
								<li>
									<a href={currHref.url} className="menu-item">
										{currHref.icon ? React.createElement(FaIcons[currHref.icon as keyof typeof FaIcons], { size: 15 }) : null}
 
									</a>
								</li>
							)
						})}
					</ul>
				)
			default:
				return null
		}
	}

	return <>{renderContent()}</>
}

export default ContentSection
