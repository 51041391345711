/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.17 model.glb -t -D 
*/

import * as THREE from 'three'
import { useRef, useEffect } from 'react'
import { useGLTF, useAnimations, meshBounds } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { ModalType } from '../../types'
import { useTarget } from '../../TargetContext'

type GLTFResult = GLTF & {
  nodes: {
    Rocks: THREE.Mesh
    Fireplace: THREE.Mesh
    Text: THREE.Mesh
    GearTwo: THREE.Mesh
    Gear: THREE.Mesh
    Wrench: THREE.Mesh
    Education_1: THREE.Mesh
    Education_2: THREE.Mesh
    Education_3: THREE.Mesh
    Education_4: THREE.Mesh
    GraduationCap_String: THREE.Mesh
    Easel: THREE.Mesh
    Easel_One: THREE.Mesh
    Easel_Three: THREE.Mesh
    Easel_Two_1: THREE.Mesh
    Easel_Two_2: THREE.Mesh
    Brushes_1: THREE.Mesh
    Brushes_2: THREE.Mesh
    Brushes_3: THREE.Mesh
    Brushes_4: THREE.Mesh
    Brushes_5: THREE.Mesh
    Brushes_Two: THREE.Mesh
    Brushes_Two_1: THREE.Mesh
    Brushes_Two_2: THREE.Mesh
    Brushes_Legs: THREE.Mesh
    Briefcase: THREE.Mesh
    Briefcase_Buttons: THREE.Mesh
    Briefcase_Flap: THREE.Mesh
    Briefcase_Strap: THREE.Mesh
    Laptop: THREE.Mesh
    Laptop_Bezels: THREE.Mesh
    Laptop_Hinge: THREE.Mesh
    Laptop_Keyboard: THREE.Mesh
    Laptop_Screen: THREE.Mesh
    Tree_1: THREE.Mesh
    Tree_2: THREE.Mesh
    Mailbox_1: THREE.Mesh
    Mailbox_2: THREE.Mesh
    Mailbox_3: THREE.Mesh
    AirTurbineBase: THREE.Mesh
    Circle001: THREE.Mesh
    Circle002: THREE.Mesh
    Plane_1: THREE.Mesh
    Plane_2: THREE.Mesh
    AirTurbineBase002: THREE.Mesh
    Circle005: THREE.Mesh
    Circle006: THREE.Mesh
    AirTurbineBase001: THREE.Mesh
    Circle008: THREE.Mesh
    Circle009: THREE.Mesh
    SolarPanelBaked: THREE.Mesh
    SolarPanelBaked004: THREE.Mesh
    SolarPanelBaked003: THREE.Mesh
    SolarPanelBaked001: THREE.Mesh
    SolarPanelBaked002: THREE.Mesh
    Mailbox001_1: THREE.Mesh
    Mailbox001_2: THREE.Mesh
    Windmill: THREE.Mesh
    Icosphere022: THREE.Mesh
    Icosphere022_1: THREE.Mesh
    House_02: THREE.Mesh
    House_07: THREE.Mesh
    House_07001: THREE.Mesh
    Dry_Tree_02: THREE.Mesh
    Dry_Tree_02001: THREE.Mesh
    Dry_Tree_02002: THREE.Mesh
    Dry_Tree_02003: THREE.Mesh
    Well: THREE.Mesh
    Corn_Field: THREE.Mesh
    House_02001: THREE.Mesh
    Mushroom: THREE.Mesh
    Mushroom_Eyes: THREE.Mesh
    Mushroom_Hat: THREE.Mesh
    Mushroom_Circle_1: THREE.Mesh
    Mushroom_Circle_2: THREE.Mesh
    Mushroom_Circle_3: THREE.Mesh
    Mushroom_Circle_4: THREE.Mesh
    Mushroom_Circle_5: THREE.Mesh
    Mushroom_Circle_6: THREE.Mesh
    Mushroom_Circle_7: THREE.Mesh
    Mushroom_Circle_8: THREE.Mesh
    Mushroom_Circle_9: THREE.Mesh
    Airplane_1: THREE.Mesh
    Airplane_2: THREE.Mesh
    Airplane_3: THREE.Mesh
    Airplane_4: THREE.Mesh
    Airplane_5: THREE.Mesh
    Water: THREE.Mesh
    TreeType1_1: THREE.Mesh
    TreeType1_2: THREE.Mesh
    Vert: THREE.Mesh
    Vert_1: THREE.Mesh
    Vert002: THREE.Mesh
    Vert004: THREE.Mesh
    PalmTree_1: THREE.Mesh
    PalmTree_2: THREE.Mesh
    Coconut001: THREE.Mesh
    Coconut001_1: THREE.Mesh
    Coconut_1: THREE.Mesh
    Coconut_2: THREE.Mesh
    Boat: THREE.Mesh
    Circle007: THREE.Mesh
    Plane: THREE.Mesh
    Plane011: THREE.Mesh
    Plane015: THREE.Mesh
    Plane016: THREE.Mesh
    Vert005: THREE.Mesh
    Plane017: THREE.Mesh
    Terrain_1: THREE.Mesh
    Terrain_2: THREE.Mesh
    Terrain_3: THREE.Mesh
    Terrain001: THREE.Mesh
    Terrain001_1: THREE.Mesh
    Terrain001_2: THREE.Mesh
    Terrain001_3: THREE.Mesh
    Terrain001_4: THREE.Mesh
    Terrain001_5: THREE.Mesh
    Terrain002: THREE.Mesh
    Terrain002_1: THREE.Mesh
    Terrain002_2: THREE.Mesh
    VillageLand: THREE.Mesh
    Terrain004: THREE.Mesh
    Terrain004_1: THREE.Mesh
    Mushroom_Land: THREE.Mesh
    VisionPro_Front001: THREE.Mesh
    VisionPro_Front001_1: THREE.Mesh
    VisionPro_Front001_2: THREE.Mesh
    VisionPro_Front001_3: THREE.Mesh
    Highrise_1_1: THREE.Mesh
    Highrise_1_2: THREE.Mesh
    Highrise_1_3: THREE.Mesh
    Highrise_2_1: THREE.Mesh
    Highrise_2_2: THREE.Mesh
    Highrise_2_3: THREE.Mesh
    Highrise_3_1: THREE.Mesh
    Highrise_3_2: THREE.Mesh
    Highrise_3_3: THREE.Mesh
    Highrise_4_1: THREE.Mesh
    Highrise_4_2: THREE.Mesh
    Highrise_4_3: THREE.Mesh
    Highrise_5_1: THREE.Mesh
    Highrise_5_2: THREE.Mesh
    Highrise_5_3: THREE.Mesh
    Highrise_6_1: THREE.Mesh
    Highrise_6_2: THREE.Mesh
    Highrise_6_3: THREE.Mesh
    Highrise_7_1: THREE.Mesh
    Highrise_7_2: THREE.Mesh
    Highrise_7_3: THREE.Mesh
    Highrise_8: THREE.Mesh
    Highrise_9_1: THREE.Mesh
    Highrise_9_2: THREE.Mesh
    Highrise_10_1: THREE.Mesh
    Highrise_10_2: THREE.Mesh
    Highrise_11_1: THREE.Mesh
    Highrise_11_2: THREE.Mesh
    Highrise_11_3: THREE.Mesh
    Highrise_12_1: THREE.Mesh
    Highrise_12_2: THREE.Mesh
    Highrise_12_3: THREE.Mesh
    highrise_13_1: THREE.Mesh
    highrise_13_2: THREE.Mesh
    highrise_13_3: THREE.Mesh
    Highrise_14_1: THREE.Mesh
    Highrise_14_2: THREE.Mesh
    Highrise_14_3: THREE.Mesh
    Highrise_15: THREE.Mesh
    Highrise_16_1: THREE.Mesh
    Highrise_16_2: THREE.Mesh
    Highrise_17_1: THREE.Mesh
    Highrise_17_2: THREE.Mesh
    Highrise_18_1: THREE.Mesh
    Highrise_18_2: THREE.Mesh
    Highrise_18_3: THREE.Mesh
    Highrise_19_1: THREE.Mesh
    Highrise_19_2: THREE.Mesh
    Highrise_19_3: THREE.Mesh
  }
  materials: {
    grey: THREE.MeshStandardMaterial
    Wood: THREE.MeshStandardMaterial
    Metal: THREE.MeshStandardMaterial
    ['beige gloss']: THREE.MeshStandardMaterial
    Yellow: THREE.MeshStandardMaterial
    Barrel: THREE.MeshStandardMaterial
    Beige: THREE.MeshStandardMaterial
    red: THREE.MeshStandardMaterial
    Canvas: THREE.MeshStandardMaterial
    Painting: THREE.MeshStandardMaterial
    ['Paint.003']: THREE.MeshStandardMaterial
    ['Paint.002']: THREE.MeshStandardMaterial
    ['Paint.001']: THREE.MeshStandardMaterial
    Paint: THREE.MeshStandardMaterial
    Dark: THREE.MeshStandardMaterial
    ['brown gloss']: THREE.MeshStandardMaterial
    ['metal.001']: THREE.MeshStandardMaterial
    ['brown gloss.001']: THREE.MeshStandardMaterial
    ['black gloss.001']: THREE.MeshStandardMaterial
    Tree: THREE.MeshStandardMaterial
    Trunk: THREE.MeshStandardMaterial
    Mailbox_Flag: THREE.MeshStandardMaterial
    Mailbox_Material: THREE.MeshStandardMaterial
    ['White GLoss']: THREE.MeshStandardMaterial
    ['Blue.002']: THREE.MeshStandardMaterial
    BakedSolarPanel: THREE.MeshStandardMaterial
    Village: THREE.MeshStandardMaterial
    ['Village.001']: THREE.MeshStandardMaterial
    ['Village.002']: THREE.MeshStandardMaterial
    ['Village.003']: THREE.MeshStandardMaterial
    ['Village.004']: THREE.MeshStandardMaterial
    ['Village.005']: THREE.MeshStandardMaterial
    ['Village.006']: THREE.MeshStandardMaterial
    ['Beige.001']: THREE.MeshStandardMaterial
    ['Black.001']: THREE.MeshStandardMaterial
    Red: THREE.MeshStandardMaterial
    ['White.001']: THREE.MeshStandardMaterial
    Plane_Red: THREE.MeshStandardMaterial
    ['Black.003']: THREE.MeshStandardMaterial
    ['Black.004']: THREE.MeshStandardMaterial
    ['black gloss']: THREE.MeshStandardMaterial
    Water: THREE.MeshPhysicalMaterial
    ['Material.006']: THREE.MeshStandardMaterial
    ['Material.007']: THREE.MeshStandardMaterial
    ['Material.004']: THREE.MeshStandardMaterial
    ['Palm Tree']: THREE.MeshStandardMaterial
    Black: THREE.MeshStandardMaterial
    Accent: THREE.MeshStandardMaterial
    Grass: THREE.MeshStandardMaterial
    Desert: THREE.MeshStandardMaterial
    Building_Concrete: THREE.MeshStandardMaterial
    Building_Window: THREE.MeshPhysicalMaterial
    Building_Metal: THREE.MeshStandardMaterial
    metal: THREE.MeshStandardMaterial
    white: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
}

type ActionName =
	| 'WrenchAction'
	| 'GearAction'
	| 'Education_Click_Action'
	| 'Education_Dismiss_Action'
	| 'Brushes_Click_Action'
	| 'Briefcase_Dismiss_Action'
	| 'Briefcase_Click_Action'
	| 'Laptop_Click_Action'
	| 'Laptop_Dismiss_Action'
	| 'MushroomAction'
	| 'AirplaneAction'
	| 'BoatAction'
interface GLTFAction extends THREE.AnimationClip {
	name: ActionName
}

export function Model(props: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null)
	const { nodes, materials, animations } = useGLTF('/model.glb') as GLTFResult

	const { actions } = useAnimations(animations, group)
	const { setCurrentModalType, setModalToPosition } = useTarget()
	useEffect(() => {
		actions.AirplaneAction?.play()
		actions.BoatAction?.play()
	}, [actions])
	useEffect(() => {
		const modalTypeToMesh = {
			[ModalType.Settings]: nodes.Gear.position,
			[ModalType.About]: nodes.Easel.position,
			[ModalType.Education]: new THREE.Vector3(2.146, 4.598, -4.155),
			[ModalType.Experience]: nodes.Briefcase.position,
			[ModalType.Projects]: new THREE.Vector3(-5.822, 4.819, -1.194),
			[ModalType.Contact]: new THREE.Vector3(-2.588, 4.063, 3.903)
		}
		setModalToPosition(modalTypeToMesh)
	}, [nodes])
	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="Settings001" position={[-0.453, 1.047, -6.59]} scale={0.15} />
				<mesh
					name="Rocks"
					geometry={nodes.Rocks.geometry}
					material={materials.grey}
					position={[5.481, 0.858, 3.283]}
					rotation={[0.381, -0.37, -1.257]}
					scale={[0.187, 0.097, 0.199]}
				/>
				<mesh
					name="Fireplace"
					geometry={nodes.Fireplace.geometry}
					material={materials.Wood}
					position={[5.263, 0.869, 3.721]}
					rotation={[-1.366, 0.293, -1.478]}
					scale={[0.035, 0.161, 0.029]}
				/>
				<mesh
					name="Text"
					geometry={nodes.Text.geometry}
					material={nodes.Text.material}
					position={[11.338, 5.001, 2.398]}
					rotation={[1.222, 0.293, 2.276]}
					scale={0.352}
				/>
				<mesh
					name="GearTwo"
					geometry={nodes.GearTwo.geometry}
					material={materials.Metal}
					position={[0.832, 1.421, -6.581]}
					rotation={[1.565, -0.037, 0.227]}
					scale={0.48}
					onClick={() => setCurrentModalType(ModalType.Settings)}
					raycast={meshBounds}
				/>
				<mesh
					name="Gear"
					geometry={nodes.Gear.geometry}
					material={materials.Metal}
					position={[0.162, 0.613, -6.717]}
					rotation={[1.843, -1.178, 0.193]}
					scale={0.314}
					onClick={() => setCurrentModalType(ModalType.Settings)}
					raycast={meshBounds}
				/>
				<mesh
					name="Wrench"
					geometry={nodes.Wrench.geometry}
					material={materials['beige gloss']}
					position={[0.7, 0.735, -6.747]}
					rotation={[1.244, 0.891, 0.424]}
					scale={[0.136, 0.683, 0.683]}
					onClick={() => setCurrentModalType(ModalType.Settings)}
					raycast={meshBounds}
				/>
				<group
					name="Education"
					position={[2.206, 4.598, -4.203]}
					rotation={[-0.24, -0.336, -0.216]}
					scale={-0.058}
					onClick={() => setCurrentModalType(ModalType.Education)}
					raycast={meshBounds}
				>
					<mesh name="Education_1" geometry={nodes.Education_1.geometry} material={materials.Yellow} />
					<mesh name="Education_2" geometry={nodes.Education_2.geometry} material={materials.Barrel} />
					<mesh name="Education_3" geometry={nodes.Education_3.geometry} material={materials.Beige} />
					<mesh name="Education_4" geometry={nodes.Education_4.geometry} material={materials.red} />
					<mesh
						name="GraduationCap_String"
						geometry={nodes.GraduationCap_String.geometry}
						material={materials.Yellow}
						position={[-18.798, -14.247, -0.082]}
						scale={-84.726}
					/>
				</group>
				<mesh
					name="Easel"
					geometry={nodes.Easel.geometry}
					material={materials.Wood}
					position={[3.441, 5.488, 1.421]}
					rotation={[0.977, 1.4, -0.943]}
					scale={0.462}
					onClick={() => setCurrentModalType(ModalType.About)}
					raycast={meshBounds}
				>
					<mesh
						name="Easel_One"
						geometry={nodes.Easel_One.geometry}
						material={materials.Wood}
						position={[0, 2.636, -0.16]}
						rotation={[0.238, 0, 0]}
					/>
					<mesh
						name="Easel_Three"
						geometry={nodes.Easel_Three.geometry}
						material={materials.Wood}
						position={[0, 1.476, 0.32]}
					/>
					<group
						name="Easel_Two"
						position={[0.005, 3.631, 0.187]}
						rotation={[1.572, 1.564, 0]}
						scale={1.182}
						onClick={() => setCurrentModalType(ModalType.About)}
						raycast={meshBounds}
					>
						<mesh name="Easel_Two_1" geometry={nodes.Easel_Two_1.geometry} material={materials.Canvas} />
						<mesh name="Easel_Two_2" geometry={nodes.Easel_Two_2.geometry} material={materials.Painting} />
					</group>
				</mesh>
				<group
					name="Brushes"
					position={[3.766, 5.182, 0.123]}
					rotation={[0.119, -0.023, -0.571]}
					scale={0.689}
					onClick={() => setCurrentModalType(ModalType.About)}
					raycast={meshBounds}
				>
					<mesh name="Brushes_1" geometry={nodes.Brushes_1.geometry} material={materials.Wood} />
					<mesh name="Brushes_2" geometry={nodes.Brushes_2.geometry} material={materials['Paint.003']} />
					<mesh name="Brushes_3" geometry={nodes.Brushes_3.geometry} material={materials['Paint.002']} />
					<mesh name="Brushes_4" geometry={nodes.Brushes_4.geometry} material={materials['Paint.001']} />
					<mesh name="Brushes_5" geometry={nodes.Brushes_5.geometry} material={materials.Paint} />
					<group name="Brushes_One" position={[-0.246, 1.536, 0.262]} rotation={[-0.022, 0.258, -0.055]} scale={0.666}>
						<mesh name="Brushes_Two" geometry={nodes.Brushes_Two.geometry} material={materials.Dark} />
						<mesh name="Brushes_Two_1" geometry={nodes.Brushes_Two_1.geometry} material={materials.Metal} />
						<mesh name="Brushes_Two_2" geometry={nodes.Brushes_Two_2.geometry} material={materials['Paint.003']} />
					</group>
					<mesh
						name="Brushes_Legs"
						geometry={nodes.Brushes_Legs.geometry}
						material={materials.Wood}
						position={[-0.36, 0.247, -0.071]}
					/>
				</group>
				<mesh
					name="Briefcase"
					geometry={nodes.Briefcase.geometry}
					material={materials['brown gloss']}
					position={[-2.826, 5, -3.666]}
					rotation={[-1.575, 1.027, 1.285]}
					scale={0.718}
					onClick={() => setCurrentModalType(ModalType.Experience)}
					raycast={meshBounds}
				>
					<mesh
						name="Briefcase_Buttons"
						geometry={nodes.Briefcase_Buttons.geometry}
						material={materials['metal.001']}
						position={[0.407, 0.443, 0.491]}
						rotation={[0, 0, -0.353]}
					/>
					<mesh
						name="Briefcase_Flap"
						geometry={nodes.Briefcase_Flap.geometry}
						material={materials['brown gloss.001']}
					/>
					<mesh name="Briefcase_Strap" geometry={nodes.Briefcase_Strap.geometry} material={materials['brown gloss']} />
				</mesh>
				<mesh
					name="Laptop"
					geometry={nodes.Laptop.geometry}
					material={materials['beige gloss']}
					position={[-1.306, 5, -4.019]}
					rotation={[2.398, 0.906, 3.138]}
					scale={0.84}
					onClick={() => setCurrentModalType(ModalType.Experience)}
					raycast={meshBounds}
				>
					<mesh
						name="Laptop_Bezels"
						geometry={nodes.Laptop_Bezels.geometry}
						material={materials['beige gloss']}
						position={[-0.976, 0.831, 0]}
						rotation={[0, 0, 1.906]}
					/>
					<mesh
						name="Laptop_Hinge"
						geometry={nodes.Laptop_Hinge.geometry}
						material={materials['beige gloss']}
						position={[-0.652, 0.263, 0.419]}
					/>
					<mesh
						name="Laptop_Keyboard"
						geometry={nodes.Laptop_Keyboard.geometry}
						material={materials.Barrel}
						position={[-0.404, 0.207, 0.722]}
						rotation={[-1.635, -1.491, -1.683]}
						scale={0.055}
					/>
					<mesh
						name="Laptop_Screen"
						geometry={nodes.Laptop_Screen.geometry}
						material={materials['black gloss.001']}
						position={[-0.976, 0.831, 0]}
						rotation={[0, 0, 1.906]}
					/>
				</mesh>
				<group name="Tree">
					<mesh name="Tree_1" geometry={nodes.Tree_1.geometry} material={materials.Tree} />
					<mesh name="Tree_2" geometry={nodes.Tree_2.geometry} material={materials.Trunk} />
				</group>
				<group
					name="Mailbox"
					position={[-2.588, 4.063, 3.903]}
					rotation={[-2.532, 1.376, 1.057]}
					scale={0.495}
					onClick={() => setCurrentModalType(ModalType.Contact)}
					raycast={meshBounds}
				>
					<mesh name="Mailbox_1" geometry={nodes.Mailbox_1.geometry} material={materials.Mailbox_Flag} />
					<mesh name="Mailbox_2" geometry={nodes.Mailbox_2.geometry} material={materials.Wood} />
					<mesh name="Mailbox_3" geometry={nodes.Mailbox_3.geometry} material={materials.Mailbox_Material} />
				</group>
				<mesh
					name="AirTurbineBase"
					geometry={nodes.AirTurbineBase.geometry}
					material={materials['White GLoss']}
					position={[2.78, -4.447, -3.733]}
					rotation={[-2.374, 1.253, 0.144]}
					scale={0.652}
				>
					<mesh
						name="Circle001"
						geometry={nodes.Circle001.geometry}
						material={materials['White GLoss']}
						position={[0, 1.96, 0.247]}
						rotation={[Math.PI / 2, 0, 0]}
						scale={0.279}
					/>
					<mesh
						name="Circle002"
						geometry={nodes.Circle002.geometry}
						material={materials['Blue.002']}
						position={[0, 1.96, 0.378]}
						rotation={[-Math.PI / 2, 0, 0]}
						scale={0.156}
					/>
					<group name="Plane002_1" position={[-0.005, 1.957, 0.21]} rotation={[-1.51, -1.046, -3.037]} scale={0.088}>
						<mesh name="Plane_1" geometry={nodes.Plane_1.geometry} material={materials['White GLoss']} />
						<mesh name="Plane_2" geometry={nodes.Plane_2.geometry} material={materials['Blue.002']} />
					</group>
				</mesh>
				<mesh
					name="AirTurbineBase002"
					geometry={nodes.AirTurbineBase002.geometry}
					material={materials['White GLoss']}
					position={[3.528, -2.099, -4.943]}
					rotation={[-1.468, 1.299, -0.543]}
					scale={0.652}
				>
					<mesh
						name="Circle005"
						geometry={nodes.Circle005.geometry}
						material={materials['White GLoss']}
						position={[0, 1.96, 0.247]}
						rotation={[Math.PI / 2, 0, 0]}
						scale={0.279}
					/>
					<mesh
						name="Circle006"
						geometry={nodes.Circle006.geometry}
						material={materials['Blue.002']}
						position={[0, 1.96, 0.378]}
						rotation={[-Math.PI / 2, 0, 0]}
						scale={0.156}
					/>
					<group name="Plane003_5" position={[-0.005, 1.957, 0.21]} rotation={[-1.51, -1.046, -3.037]} scale={0.088}>
						<mesh name="Plane_1" geometry={nodes.Plane_1.geometry} material={materials['White GLoss']} />
						<mesh name="Plane_2" geometry={nodes.Plane_2.geometry} material={materials['Blue.002']} />
					</group>
				</mesh>
				<mesh
					name="AirTurbineBase001"
					geometry={nodes.AirTurbineBase001.geometry}
					material={materials['White GLoss']}
					position={[3.063, -3.47, -4.66]}
					rotation={[-2.008, 1.038, -0.022]}
					scale={0.652}
				>
					<mesh
						name="Circle008"
						geometry={nodes.Circle008.geometry}
						material={materials['White GLoss']}
						position={[0, 1.96, 0.247]}
						rotation={[Math.PI / 2, 0, 0]}
						scale={0.279}
					/>
					<mesh
						name="Circle009"
						geometry={nodes.Circle009.geometry}
						material={materials['Blue.002']}
						position={[0, 1.96, 0.378]}
						rotation={[-Math.PI / 2, 0, 0]}
						scale={0.156}
					/>
					<group name="Plane004" position={[-0.005, 1.957, 0.21]} rotation={[-1.51, -1.046, -3.037]} scale={0.088}>
						<mesh name="Plane_1" geometry={nodes.Plane_1.geometry} material={materials['White GLoss']} />
						<mesh name="Plane_2" geometry={nodes.Plane_2.geometry} material={materials['Blue.002']} />
					</group>
				</mesh>
				<mesh
					name="SolarPanelBaked"
					geometry={nodes.SolarPanelBaked.geometry}
					material={materials.BakedSolarPanel}
					position={[4.055, 0.932, -5.179]}
					rotation={[-2.166, -0.816, -0.868]}
					scale={0.357}
				/>
				<mesh
					name="SolarPanelBaked004"
					geometry={nodes.SolarPanelBaked004.geometry}
					material={materials.BakedSolarPanel}
					position={[2.907, -0.737, -5.567]}
					rotation={[-2.152, -0.861, -0.807]}
					scale={0.357}
				/>
				<mesh
					name="SolarPanelBaked003"
					geometry={nodes.SolarPanelBaked003.geometry}
					material={materials.BakedSolarPanel}
					position={[2.608, 0.917, -5.789]}
					rotation={[-1.502, -0.551, -0.519]}
					scale={0.357}
				/>
				<mesh
					name="SolarPanelBaked001"
					geometry={nodes.SolarPanelBaked001.geometry}
					material={materials.BakedSolarPanel}
					position={[1.765, -0.106, -6.156]}
					rotation={[-1.75, -0.397, -0.327]}
					scale={0.357}
				/>
				<mesh
					name="SolarPanelBaked002"
					geometry={nodes.SolarPanelBaked002.geometry}
					material={materials.BakedSolarPanel}
					position={[1.13, -1.171, -6.155]}
					rotation={[-1.335, -1.501, 0.397]}
					scale={0.357}
				/>
				<group name="Mailbox001" position={[-2.509, 5.754, 5.596]}>
					<mesh name="Mailbox001_1" geometry={nodes.Mailbox001_1.geometry} material={materials.Mailbox_Flag} />
					<mesh name="Mailbox001_2" geometry={nodes.Mailbox001_2.geometry} material={materials.Mailbox_Material} />
				</group>
				<mesh
					name="Windmill"
					geometry={nodes.Windmill.geometry}
					material={materials.Village}
					position={[-6.322, -0.278, -4.466]}
					rotation={[0.979, -0.451, 2.01]}
					scale={0.147}
				/>
				<group name="Tree_03" position={[-6.571, -1.742, -1.375]} rotation={[-0.157, -0.207, 1.883]} scale={0.144}>
					<mesh name="Icosphere022" geometry={nodes.Icosphere022.geometry} material={materials['Village.001']} />
					<mesh name="Icosphere022_1" geometry={nodes.Icosphere022_1.geometry} material={materials.Tree} />
				</group>
				<group name="Tree_03001" position={[-5.874, -3.033, -2.443]} rotation={[-0.157, -0.207, 1.883]} scale={0.144}>
					<mesh name="Icosphere022" geometry={nodes.Icosphere022.geometry} material={materials['Village.001']} />
					<mesh name="Icosphere022_1" geometry={nodes.Icosphere022_1.geometry} material={materials.Tree} />
				</group>
				<group name="Tree_03002" position={[-6.665, -0.435, -2.299]} rotation={[-0.105, -0.057, 1.796]} scale={0.144}>
					<mesh name="Icosphere022" geometry={nodes.Icosphere022.geometry} material={materials['Village.001']} />
					<mesh name="Icosphere022_1" geometry={nodes.Icosphere022_1.geometry} material={materials.Tree} />
				</group>
				<group name="Tree_03003" position={[-6.812, 0.095, -0.982]} rotation={[-0.264, -0.023, 1.311]} scale={0.144}>
					<mesh name="Icosphere022" geometry={nodes.Icosphere022.geometry} material={materials['Village.001']} />
					<mesh name="Icosphere022_1" geometry={nodes.Icosphere022_1.geometry} material={materials.Tree} />
				</group>
				<mesh
					name="House_02"
					geometry={nodes.House_02.geometry}
					material={materials['Village.002']}
					position={[-6.209, -2.575, -1.437]}
					rotation={[-3.089, 0.165, 1.258]}
					scale={0.125}
				/>
				<mesh
					name="House_07"
					geometry={nodes.House_07.geometry}
					material={materials['Village.003']}
					position={[-6.859, 0.169, 0.043]}
					rotation={[-0.088, -0.165, 1.51]}
					scale={0.106}
				/>
				<mesh
					name="House_07001"
					geometry={nodes.House_07001.geometry}
					material={materials['Village.003']}
					position={[-6.444, -1.293, -2.292]}
					rotation={[1.071, 0.123, 1.85]}
					scale={0.106}
				/>
				<mesh
					name="Dry_Tree_02"
					geometry={nodes.Dry_Tree_02.geometry}
					material={materials['Village.004']}
					position={[-5.528, -2.297, -3.985]}
					rotation={[0.099, -0.809, 1.963]}
					scale={0.165}
				/>
				<mesh
					name="Dry_Tree_02001"
					geometry={nodes.Dry_Tree_02001.geometry}
					material={materials['Village.004']}
					position={[-6.243, -1.855, -3.281]}
					rotation={[-0.098, -0.197, 1.773]}
					scale={0.165}
				/>
				<mesh
					name="Dry_Tree_02002"
					geometry={nodes.Dry_Tree_02002.geometry}
					material={materials['Village.004']}
					position={[-5.976, -1.388, -3.936]}
					rotation={[0.004, -0.593, 1.724]}
					scale={0.165}
				/>
				<mesh
					name="Dry_Tree_02003"
					geometry={nodes.Dry_Tree_02003.geometry}
					material={materials['Village.004']}
					position={[-6.545, -0.798, -3.026]}
					rotation={[-0.092, -0.216, 1.763]}
					scale={0.165}
				/>
				<mesh
					name="Well"
					geometry={nodes.Well.geometry}
					material={materials['Village.005']}
					position={[-6.696, -0.649, -1.492]}
					rotation={[0, 0, 1.662]}
					scale={0.251}
				/>
				<mesh
					name="Corn_Field"
					geometry={nodes.Corn_Field.geometry}
					material={materials['Village.006']}
					position={[-6.451, 0.799, -1.634]}
					rotation={[-0.081, -0.178, 1.421]}
					scale={0.092}
				/>
				<mesh
					name="House_02001"
					geometry={nodes.House_02001.geometry}
					material={materials['Village.002']}
					position={[-6.766, -0.885, -0.577]}
					rotation={[0.366, 0.146, 1.645]}
					scale={0.125}
				/>
				<mesh
					name="Mushroom"
					geometry={nodes.Mushroom.geometry}
					material={materials['Beige.001']}
					position={[4.058, 1.048, 4.898]}
					rotation={[0.561, -0.433, -0.828]}
					scale={1.472}
					onClick={() => {
						actions.MushroomAction?.play()
					}}
					raycast={meshBounds}
				>
					<mesh
						name="Mushroom_Eyes"
						geometry={nodes.Mushroom_Eyes.geometry}
						material={materials['Black.001']}
						position={[-0.113, 0.512, 0.405]}
						rotation={[-0.077, 0, 0]}
					/>
					<mesh
						name="Mushroom_Hat"
						geometry={nodes.Mushroom_Hat.geometry}
						material={materials.Red}
						position={[0, 0.368, 0.055]}
						rotation={[-0.428, 0, 0]}
						scale={0.741}
					>
						<mesh
							name="Mushroom_Circle_1"
							geometry={nodes.Mushroom_Circle_1.geometry}
							material={materials['White GLoss']}
							position={[0.533, 0.516, 0.737]}
							rotation={[0.861, 0.468, -0.418]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_2"
							geometry={nodes.Mushroom_Circle_2.geometry}
							material={materials['White GLoss']}
							position={[-0.033, 0.918, 0.499]}
							rotation={[0.175, 0.194, 0.132]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_3"
							geometry={nodes.Mushroom_Circle_3.geometry}
							material={materials['White GLoss']}
							position={[-0.624, 0.415, 0.729]}
							rotation={[0.809, -0.195, 0.648]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_4"
							geometry={nodes.Mushroom_Circle_4.geometry}
							material={materials['White GLoss']}
							position={[-0.741, 0.736, 0.054]}
							rotation={[0.323, -0.674, 0.805]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_5"
							geometry={nodes.Mushroom_Circle_5.geometry}
							material={materials['White GLoss']}
							position={[-0.133, 0.992, -0.303]}
							rotation={[-0.599, -0.723, -0.07]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_6"
							geometry={nodes.Mushroom_Circle_6.geometry}
							material={materials['White GLoss']}
							position={[0.646, 0.823, -0.007]}
							rotation={[-0.75, -0.326, -0.881]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_7"
							geometry={nodes.Mushroom_Circle_7.geometry}
							material={materials['White GLoss']}
							position={[0.448, 0.593, -0.736]}
							rotation={[-1.04, 0.449, -0.318]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_8"
							geometry={nodes.Mushroom_Circle_8.geometry}
							material={materials['White GLoss']}
							position={[1.025, 0.132, 0.162]}
							rotation={[-0.281, 0.126, -1.454]}
							scale={1.307}
						/>
						<mesh
							name="Mushroom_Circle_9"
							geometry={nodes.Mushroom_Circle_9.geometry}
							material={materials['White GLoss']}
							position={[-0.028, 0.2, 1.147]}
							rotation={[-1.138, -1.111, -2.565]}
							scale={1.307}
						/>
					</mesh>
				</mesh>
        <group name="Airplane" position={[-9.116, 0, -0.571]} rotation={[Math.PI, 0.062, 0]} scale={[1, 1.489, 1.028]}>
          <mesh name="Airplane_1" geometry={nodes.Airplane_1.geometry} material={materials['White.001']} />
          <mesh name="Airplane_2" geometry={nodes.Airplane_2.geometry} material={materials.Plane_Red} />
          <mesh name="Airplane_3" geometry={nodes.Airplane_3.geometry} material={materials['Black.003']} />
          <mesh name="Airplane_4" geometry={nodes.Airplane_4.geometry} material={materials['Black.004']} />
          <mesh name="Airplane_5" geometry={nodes.Airplane_5.geometry} material={materials['black gloss']} />
        </group>				
        <mesh name="Water" geometry={nodes.Water.geometry} material={materials.Water} scale={6.346} />
				<group name="TreeType1" position={[4.393, 0.07, 4.933]} rotation={[1.553, 0.039, -1.153]} scale={0.66}>
					<mesh name="TreeType1_1" geometry={nodes.TreeType1_1.geometry} material={materials.Tree} />
					<mesh name="TreeType1_2" geometry={nodes.TreeType1_2.geometry} material={materials.Trunk} />
				</group>
				<group name="Beachchair" position={[-2.85, -3.076, -4.821]} rotation={[-1.852, -0.634, 0.438]} scale={0.334}>
					<mesh name="Vert" geometry={nodes.Vert.geometry} material={materials['Material.006']} />
					<mesh name="Vert_1" geometry={nodes.Vert_1.geometry} material={materials['Material.007']} />
					<mesh name="Vert002" geometry={nodes.Vert002.geometry} material={materials['Material.004']} />
					<mesh name="Vert004" geometry={nodes.Vert004.geometry} material={materials['Material.004']} />
				</group>
				<group name="PalmTree" position={[-2.361, -3.497, -5.003]} rotation={[-1.609, -0.936, 0.173]} scale={0.306}>
					<mesh name="PalmTree_1" geometry={nodes.PalmTree_1.geometry} material={materials.Tree} />
					<mesh name="PalmTree_2" geometry={nodes.PalmTree_2.geometry} material={materials['Palm Tree']} />
				</group>
				<group name="Coconut" position={[-2.551, -3.975, -7.382]} rotation={[-0.161, -0.27, -1.14]} scale={0.198}>
					<mesh name="Coconut001" geometry={nodes.Coconut001.geometry} material={materials.Trunk} />
					<mesh name="Coconut001_1" geometry={nodes.Coconut001_1.geometry} material={materials.Black} />
					<group name="Icosphere001" position={[-1.427, 0.626, 0.134]} rotation={[-0.871, 0.06, 1.582]}>
						<mesh name="Coconut_1" geometry={nodes.Coconut_1.geometry} material={materials.Trunk} />
						<mesh name="Coconut_2" geometry={nodes.Coconut_2.geometry} material={materials.Black} />
					</group>
				</group>
				<mesh
					name="Boat"
					geometry={nodes.Boat.geometry}
					material={materials.Wood}
					position={[5.676, 3.027, 0.835]}
					rotation={[0.13, -0.077, -1.071]}
					scale={0.412}
				>
					<mesh
						name="Circle007"
						geometry={nodes.Circle007.geometry}
						material={materials.Wood}
						position={[0, 0.559, -2.18]}
						scale={1.361}
					/>
					<mesh name="Plane" geometry={nodes.Plane.geometry} material={materials['Blue.002']} />
					<mesh
						name="Plane011"
						geometry={nodes.Plane011.geometry}
						material={materials.Wood}
						position={[0, 0, -0.387]}
					/>
					<mesh name="Plane015" geometry={nodes.Plane015.geometry} material={materials.Wood} />
					<mesh name="Plane016" geometry={nodes.Plane016.geometry} material={materials.Wood} />
					<mesh
						name="Vert005"
						geometry={nodes.Vert005.geometry}
						material={materials.Wood}
						position={[0, 0, 1.235]}
						rotation={[0, -1.124, 0]}
					>
						<mesh name="Plane017" geometry={nodes.Plane017.geometry} material={materials.Accent} />
					</mesh>
				</mesh>
				<group name="Terrain" scale={6}>
					<mesh name="Terrain_1" geometry={nodes.Terrain_1.geometry} material={materials.Grass} />
					<mesh name="Terrain_2" geometry={nodes.Terrain_2.geometry} material={materials.Desert} />
					<mesh name="Terrain_3" geometry={nodes.Terrain_3.geometry} material={materials.Grass} />
				</group>
				<group name="CityLand" position={[-0.383, -0.31, -0.081]} scale={[0.914, 0.914, 1]}>
					<mesh name="Terrain001" geometry={nodes.Terrain001.geometry} material={materials.Grass} />
					<mesh name="Terrain001_1" geometry={nodes.Terrain001_1.geometry} material={materials.Tree} />
					<mesh name="Terrain001_2" geometry={nodes.Terrain001_2.geometry} material={materials.Trunk} />
					<mesh name="Terrain001_3" geometry={nodes.Terrain001_3.geometry} material={materials.Building_Concrete} />
					<mesh name="Terrain001_4" geometry={nodes.Terrain001_4.geometry} material={materials.Building_Window} />
					<mesh name="Terrain001_5" geometry={nodes.Terrain001_5.geometry} material={materials.Building_Metal} />
				</group>
				<group name="TopTerrain" scale={6}>
					<mesh name="Terrain002" geometry={nodes.Terrain002.geometry} material={materials.Grass} />
					<mesh name="Terrain002_1" geometry={nodes.Terrain002_1.geometry} material={materials.Tree} />
					<mesh name="Terrain002_2" geometry={nodes.Terrain002_2.geometry} material={materials.Trunk} />
				</group>
				<mesh name="VillageLand" geometry={nodes.VillageLand.geometry} material={materials.Grass} scale={6} />
				<group name="Desert" scale={6}>
					<mesh name="Terrain004" geometry={nodes.Terrain004.geometry} material={materials.Grass} />
					<mesh name="Terrain004_1" geometry={nodes.Terrain004_1.geometry} material={materials.Desert} />
				</group>
				<mesh name="Mushroom_Land" geometry={nodes.Mushroom_Land.geometry} material={materials.Grass} scale={6} />
				<group
					name="VisionPro"
					position={[-5.822, 4.819, -1.194]}
					rotation={[1.824, -1.049, 2.006]}
					scale={1.255}
					onClick={() => setCurrentModalType(ModalType.Projects)}
					raycast={meshBounds}
				>
					<mesh
						name="VisionPro_Front001"
						geometry={nodes.VisionPro_Front001.geometry}
						material={materials['black gloss']}
					/>
					<mesh name="VisionPro_Front001_1" geometry={nodes.VisionPro_Front001_1.geometry} material={materials.metal} />
					<mesh name="VisionPro_Front001_2" geometry={nodes.VisionPro_Front001_2.geometry} material={materials.white} />
					<mesh name="VisionPro_Front001_3" geometry={nodes.VisionPro_Front001_3.geometry} material={materials.grey} />
				</group>
				<group name="Highrise_1" scale={0.002}>
					<mesh name="Highrise_1_1" geometry={nodes.Highrise_1_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_1_2" geometry={nodes.Highrise_1_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_1_3" geometry={nodes.Highrise_1_3.geometry} material={materials.Building_Metal} />
				</group>
				<group name="Highrise_2" scale={0.002}>
					<mesh name="Highrise_2_1" geometry={nodes.Highrise_2_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_2_2" geometry={nodes.Highrise_2_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_2_3" geometry={nodes.Highrise_2_3.geometry} material={materials.Building_Metal} />
				</group>
				<group name="Highrise_3" scale={0.002}>
					<mesh name="Highrise_3_1" geometry={nodes.Highrise_3_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_3_2" geometry={nodes.Highrise_3_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_3_3" geometry={nodes.Highrise_3_3.geometry} material={materials.Building_Metal} />
				</group>
				<group name="Highrise_4" scale={0.002}>
					<mesh name="Highrise_4_1" geometry={nodes.Highrise_4_1.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_4_2" geometry={nodes.Highrise_4_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_4_3" geometry={nodes.Highrise_4_3.geometry} material={materials.Building_Concrete} />
				</group>
				<group name="Highrise_5" scale={0.002}>
					<mesh name="Highrise_5_1" geometry={nodes.Highrise_5_1.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_5_2" geometry={nodes.Highrise_5_2.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_5_3" geometry={nodes.Highrise_5_3.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_6" scale={0.002}>
					<mesh name="Highrise_6_1" geometry={nodes.Highrise_6_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_6_2" geometry={nodes.Highrise_6_2.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_6_3" geometry={nodes.Highrise_6_3.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_7" scale={0.002}>
					<mesh name="Highrise_7_1" geometry={nodes.Highrise_7_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_7_2" geometry={nodes.Highrise_7_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_7_3" geometry={nodes.Highrise_7_3.geometry} material={materials.Building_Metal} />
				</group>
				<mesh
					name="Highrise_8"
					geometry={nodes.Highrise_8.geometry}
					material={materials.Building_Concrete}
					scale={0.002}
				/>
				<group name="Highrise_9" scale={0.002}>
					<mesh name="Highrise_9_1" geometry={nodes.Highrise_9_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_9_2" geometry={nodes.Highrise_9_2.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_10" scale={0.002}>
					<mesh name="Highrise_10_1" geometry={nodes.Highrise_10_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_10_2" geometry={nodes.Highrise_10_2.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_11" scale={0.002}>
					<mesh name="Highrise_11_1" geometry={nodes.Highrise_11_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_11_2" geometry={nodes.Highrise_11_2.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_11_3" geometry={nodes.Highrise_11_3.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_12" scale={0.002}>
					<mesh name="Highrise_12_1" geometry={nodes.Highrise_12_1.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_12_2" geometry={nodes.Highrise_12_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_12_3" geometry={nodes.Highrise_12_3.geometry} material={materials.Building_Concrete} />
				</group>
				<group name="highrise_13" scale={0.002}>
					<mesh name="highrise_13_1" geometry={nodes.highrise_13_1.geometry} material={materials.Building_Metal} />
					<mesh name="highrise_13_2" geometry={nodes.highrise_13_2.geometry} material={materials.Building_Window} />
					<mesh name="highrise_13_3" geometry={nodes.highrise_13_3.geometry} material={materials.Building_Concrete} />
				</group>
				<group name="Highrise_14" scale={0.002}>
					<mesh name="Highrise_14_1" geometry={nodes.Highrise_14_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_14_2" geometry={nodes.Highrise_14_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_14_3" geometry={nodes.Highrise_14_3.geometry} material={materials.Building_Metal} />
				</group>
				<mesh
					name="Highrise_15"
					geometry={nodes.Highrise_15.geometry}
					material={materials.Building_Concrete}
					scale={0.002}
				/>
				<group name="Highrise_16" scale={0.002}>
					<mesh name="Highrise_16_1" geometry={nodes.Highrise_16_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_16_2" geometry={nodes.Highrise_16_2.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_17" scale={0.002}>
					<mesh name="Highrise_17_1" geometry={nodes.Highrise_17_1.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_17_2" geometry={nodes.Highrise_17_2.geometry} material={materials.Building_Window} />
				</group>
				<group name="Highrise_18" scale={0.002}>
					<mesh name="Highrise_18_1" geometry={nodes.Highrise_18_1.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_18_2" geometry={nodes.Highrise_18_2.geometry} material={materials.Building_Window} />
					<mesh name="Highrise_18_3" geometry={nodes.Highrise_18_3.geometry} material={materials.Building_Concrete} />
				</group>
				<group name="Highrise_19" scale={0.002}>
					<mesh name="Highrise_19_1" geometry={nodes.Highrise_19_1.geometry} material={materials.Building_Metal} />
					<mesh name="Highrise_19_2" geometry={nodes.Highrise_19_2.geometry} material={materials.Building_Concrete} />
					<mesh name="Highrise_19_3" geometry={nodes.Highrise_19_3.geometry} material={materials.Building_Window} />
				</group>
			</group>
		</group>
	)
}

useGLTF.preload('/model.glb')
