import { TrackballControls as TrackballControlsImpl } from 'three-stdlib'
import { Perf } from 'r3f-perf'
import { TrackballControls, CameraControls, Environment, Stage } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import CloudRender from './ModelRenders/CloudRender'
import React from 'react'
import { ControlType, ModalType, Settings } from '../types'
import { useTarget } from '../TargetContext'
import { Model } from './ModelRenders/Model'
import { Vector3 } from 'three'
import { useThree, useFrame } from '@react-three/fiber'
export interface ExperienceProps {
	settings: Settings
	onCameraMove: (position: Vector3) => void
	helperTextShown: boolean
}

export default function Experience(props: ExperienceProps) {
	const trackballRef = useRef<TrackballControlsImpl>(null)
	const cameraControlsRef = useRef<CameraControls>(null)
	const { settings } = props
	const { currentModalType, modalToPosition } = useTarget()
	const { camera } = useThree()
	const [controlType, setControlType] = useState<ControlType>(settings.controlType)

	useEffect(() => {
		setControlType(settings.controlType)
	}, [settings.controlType])

	useFrame(() => {
		props.onCameraMove(camera.position.clone())
	})

	useEffect(() => {
		let target = new Vector3(0, 0, 0)

		if (currentModalType && modalToPosition[currentModalType]) {
			target = modalToPosition[currentModalType]
		} else if (props.helperTextShown && modalToPosition[ModalType.About]) {
			target = modalToPosition[ModalType.About]
		}

		if (controlType === ControlType.Default) {
			if (trackballRef.current) {
				trackballRef.current.reset()
			}
			const angle = Math.atan2(target.z, target.x)
			const radius = 30

			const xOffset = radius * Math.cos(angle)
			const zOffset = radius * Math.sin(angle)
			const yOffset = 0
			cameraControlsRef.current?.setLookAt(
				target.x + xOffset,
				target.y + yOffset,
				target.z + zOffset,
				target.x,
				target.y,
				target.z,
				true,
			)
		}
	}, [controlType, currentModalType, modalToPosition, props.helperTextShown])

	return (
		<>
			<Environment preset="apartment" />
			{settings.showPerformance && <Perf position="bottom-left" />}
			{controlType === ControlType.Default && (
				<CameraControls
					ref={cameraControlsRef}
					minPolarAngle={Math.PI * 0.5}
					maxPolarAngle={Math.PI * 0.5}
					dollySpeed={0.1}
					smoothTime={1}
					minDistance={5}
					maxDistance={50} 
					makeDefault
				/>
			)}
			{controlType === ControlType.Spinnin && (
				<TrackballControls ref={trackballRef} panSpeed={1} rotateSpeed={6} makeDefault />
			)}
			<StagedModel disabled={controlType === ControlType.Spinnin}>
				<Model />
			</StagedModel>
			<CloudRender />
		</>
	)
}

interface StagedModelProps {
	disabled: boolean
	children: React.ReactNode
}

const StagedModel: React.FC<StagedModelProps> = ({ disabled, children }) => {
	return !disabled ? (
		<Stage preset="rembrandt" intensity={1} adjustCamera={false} shadows="contact" center={{ disable: true }}>
			{children}
		</Stage>
	) : (
		<>{children}</>
	)
}
