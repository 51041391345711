import { Vector3 } from 'three'
export enum ControlType {
	Default = 'default',
	Spinnin = 'spinnin',
}

export enum ModalType {
	Settings = 'settings',
	About = 'about',
	Education = 'education',
	Experience = 'experience',
	Projects = 'projects',
	Contact = 'contact',
}

export interface Settings {
	controlType: ControlType
	animations: boolean
	lighting: boolean
	sound: boolean
	showPerformance: boolean
}

export interface Target {
	target: Vector3
	modalType: ModalType
}
export interface ParagraphContent {
	type: 'showcase' | 'description' | 'collapse' | 'timeline' | 'carousel'
	title?: string
	description: string
	href?: {
		url: string
		text: string
		icon?: string
	}[]
	image?: string
	extraDescription?: string
	tags?: Tag[]
	items?: ParagraphContent[] // Allow items to be a list of ParagraphContent
	date?: Date
	isFiltered?: boolean
}
export enum TagType {
	Featured = 'featured',
	Education = 'education',
	Default = 'default',
}
export interface Tag {
	text: string
	type?: TagType
	shown?: boolean
}

export interface ModalContent {
	title: string
	paragraphs: ParagraphContent[]
	footer: string
}

export const content: Record<ModalType, ModalContent> = {
	settings: {
		title: 'Settings',
		paragraphs: [
			{
				type: 'showcase',
				description:
					'This is where you can change the settings for the application, including control types, animations, and sound options.',
			},
		],
		footer: 'Press ESC key or click on ✕ button to close',
	},
	about: {
		title: 'About',
		paragraphs: [
			{
				type: 'description',
				title: 'Introduction',
				description:
					'Hello! My name is <strong>Gabriel Weinbrenner</strong> and I am a developer/designer based in New York City. <br /> <br /> \
					I specialize in creating interactive experiences for different platforms including <strong>Mixed Reality</strong> and the <strong>Web</strong>.<br /> <br />\
					My work combines technology with design to deliver <strong>immersive experiences</strong> for clients and users.<br /> <br /> ',
			},
			{
				type: 'description',
				title: 'Skills',
				description: 'I am proficient in a wide range of technologies and tools, including:',
				items: [
					{
						type: 'description',
						title: 'Programming Languages',
						description: 'Python, JavaScript, C, C++, Swift, Java',
					},
					{
						type: 'description',
						title: 'Frameworks & Libraries',
						description: 'VisionOS Development, React, Next.js, Tailwind CSS, TensorFlow, PyTorch',
					},
					{ type: 'description', title: 'Tools & Platforms', description: 'Docker, Azure, AWS, Git, GitHub' },
					{ type: 'description', title: 'Databases', description: 'SQL, NoSQL, MongoDB, PostgreSQL' },
					{
						type: 'description',
						title: 'Other',
						description: 'Data Analysis, Machine Learning, AI, Web Development, 3D Modelling',
					},
				],
			},
			{
				type: 'showcase',
				description: 'For a detailed view of my experience and education, you can download my resume.',
				href: [{ url: '/gabriel_weinbrenner.pdf', text: 'Download Resume', icon: 'FaDownload' }],
			},
		],
		footer: 'For more information, contact me or explore the other sections of this portfolio.',
	},
	education: {
		title: 'Education',
		paragraphs: [
			{
				type: 'description',
				title: 'Rutgers University',
				description: 'I have a Bachelor of Science in Computer Science from Rutgers University',
				isFiltered: false,
			},
			{
				type: 'collapse',
				title: 'CS111 - Intro to Computer Science',
				description: 'Foundational course in computer science covering basic programming concepts and algorithms.',
				tags: [{ text: 'Fall 2020', type: TagType.Education }, { text: 'Foundations' }],
				date: new Date('2020-09-01'),
			},
			{
				type: 'collapse',
				title: 'CS112 - Data Structures',
				description: 'In-depth study of data structures and algorithms, focusing on efficiency and optimization.',
				tags: [{ text: 'Spring 2021', type: TagType.Education }, { text: 'Java' }],
				date: new Date('2021-01-01'),
			},
			{
				type: 'collapse',
				title: 'CS211 - Computer Architecture',
				description:
					'Introduction to computer architecture and systems programming, including assembly language and memory management.',
				tags: [
					{ text: 'Fall 2021', type: TagType.Education },
					{ text: 'C Programming' },
					{ text: 'Assembly Language' },
				],
				date: new Date('2021-09-01'),
			},
			{
				type: 'collapse',
				title: 'CS336 - Principles of Information and Data Management',
				description: 'Study of database management systems and information retrieval, including SQL and data modeling.',
				tags: [{ text: 'Spring 2022', type: TagType.Education }, { text: 'SQL' }, { text: 'Database Management' }],
				date: new Date('2022-01-01'),
			},
			{
				type: 'collapse',
				title: 'CS213 - Software Methodology',
				description:
					'Introduction to software engineering principles and practices, including version control and testing.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'Fall 2022', type: TagType.Education },
					{ text: 'Java' },
					{ text: 'Design Patterns' },
				],
				date: new Date('2022-09-01'),
			},
			{
				type: 'collapse',
				title: 'CS214 - Systems Programming',
				description:
					'Advanced study of systems programming and operating systems, including file systems and process management.',
				tags: [
					{ text: 'Fall 2022', type: TagType.Education },
					{ text: 'C Programming' },
					{ text: 'Operating Systems' },
				],
				date: new Date('2022-09-01'),
			},
			{
				type: 'collapse',
				title: 'CS344 - Design and Analysis of Computer Algorithms',
				description:
					'In-depth study of algorithms and algorithm design techniques, including dynamic programming and graph algorithms.',
				tags: [{ text: 'Fall 2022', type: TagType.Education }, { text: 'Algorithms' }, { text: 'Dynamic Programming' }],
				date: new Date('2022-09-01'),
			},
			{
				type: 'collapse',
				title: 'CS314 - Principles of Programming Languages',
				description:
					'Study of programming language concepts and paradigms, including functional and object-oriented programming.',
				tags: [
					{ text: 'Spring 2023', type: TagType.Education },
					{ text: 'Programming Languages' },
					{ text: 'Functional Programming' },
				],
				date: new Date('2023-01-01'),
			},
			{
				type: 'collapse',
				title: 'CS440 - Artificial Intelligence',
				description:
					'Introduction to artificial intelligence concepts and techniques, including search algorithms and machine learning.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'Spring 2023', type: TagType.Education },
					{ text: 'AI' },
					{ text: 'Machine Learning' },
				],
				date: new Date('2023-01-01'),
			},
			{
				type: 'collapse',
				title: 'CS314 - Software Engineering',
				description: 'Study of software engineering principles and practices, including software design and testing.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'Spring 2023', type: TagType.Education },
					{ text: 'CI/CD' },
					{ text: 'Testing' },
				],
				date: new Date('2023-01-01'),
			},
		],
		footer: 'Feel free to ask any questions regarding my education.',
	},
	experience: {
		title: 'Experience',
		paragraphs: [
			{
				type: 'timeline',
				title: '',
				description: '',
				items: [
					{
						type: 'description',
						title: 'Software Developer - FliptRx',
						description:
							'Collaborated with a team of developers to design and implement features for the FliptRx mobile application.',
						extraDescription: 'September 2023 - June 2024',
						tags: [{ text: 'iOS' }, { text: 'Web Developer' }, { text: 'Software Developer' }],
						// href: [{ url: 'https://example.com', text: 'Company Website', icon: 'FaExternalLinkAlt' }],
						date: new Date('2023-09-01'),
					},
					{
						type: 'description',
						title: 'iOS Intern - Walmart',
						description:
							'Worked on developing iOS applications for Walmart, focusing on user interface design and performance optimization.',
						extraDescription: 'June 2023 - August 2023',
						tags: [{ text: 'iOS' }, { text: 'Internship' }],
						// href: [{ url: 'https://example.com', text: 'Company Website', icon: 'FaExternalLinkAlt' }],
						date: new Date('2023-06-01'),
					},
					{
						type: 'description',
						title: 'Software Fellow - Major League Hacking',
						description:
							'Participated in a fellowship program focused on open-source contributions and community building within the tech industry.',
						extraDescription: 'October 2022 - January 2023',
						tags: [{ text: 'Open Source' }, { text: 'Fellowship' }],
						href: [{ url: 'https://mlh.io', text: 'MLH Website', icon: 'FaExternalLinkAlt' }],
						date: new Date('2022-10-01'),
					},
					{
						type: 'description',
						title: 'Software Intern - FliptRx',
						description:
							'Collaborated with a team of developers to design and implement features for the FliptRx mobile application.',
						extraDescription: 'October 2021 - June 2023',
						tags: [{ text: 'Web Development' }, { text: 'Internship' }],
						// href: [{ url: '', text: 'Company Website', icon: 'FaExternalLinkAlt' }],
						date: new Date('2021-10-01'),
					},
				],
			},
		],
		footer: 'Please refer to my resume for more details.',
	},
	projects: {
		title: 'Projects',
		paragraphs: [
			{
				type: 'showcase',
				title: 'Elemental Bending',
				description:
					'An interactive application that allows users to control and bend elements like water, fire, and air using VisionOS.',
				href: [
					{ url: 'https://apps.apple.com/app/elemental-command/id6504262637', text: 'App Store', icon: 'FaAppStore' }
					// { url: 'https://www.youtube.com/watch?v=p-SsiYpPSlM', text: 'Demo', icon: 'FaRegPlayCircle'}

				],
				image: 'elemental_bending.png',
				extraDescription: 'This project combines physics simulations with user interactions for a unique experience.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'VisionOS' },
					{ text: 'Swift' },
					{ text: 'Physics Simulation' },
					{ text: 'Game Development' },
				],
				date: new Date('2024-06-01'),
			},
			{
				type: 'showcase',
				title: 'Particle Simulation',
				description: 'A particle simulation project that visualizes complex particle systems in VisionOS.',
				href: [
					{
						url: 'https://apps.apple.com/us/app/particle-simulation/id6479706693',
						text: 'App Store',
						icon: 'FaAppStore',
					},
				],
				image: 'particle_simulation.png',
				extraDescription: 'Discover the power of VisionOS in handling and rendering intricate particle systems.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'Particle Systems' },
					{ text: 'VisionOS' },
					{ text: 'Swift' },
				],
				date: new Date('2024-04-01'),
			},
			{
				type: 'showcase',
				title: 'UNO in VisionOS',
				description:
					'A project that brings the classic card game UNO to VisionOS, allowing for an immersive and interactive experience.',
				href: [
					// { url: '', text: 'GitHub', icon: 'FaGithub' },
					{ url: 'https://x.com/cocog213/status/1755923124221080011', text: 'Demo', icon: 'FaRegPlayCircle' },
				],
				image: 'uno_vision.png',
				extraDescription: 'This project demonstrates the use of VisionOS to create engaging gaming experiences.',
				tags: [
					{ text: 'Featured', type: TagType.Featured },
					{ text: 'Game Development' },
					{ text: 'VisionOS' },
					{ text: 'Swift' },
				],
				date: new Date('2024-02-01'),
			},
			{
				type: 'showcase',
				title: 'Chess in VisionOS',
				description: 'An interactive chess game developed for VisionOS, showcasing advanced AI for game mechanics.',
				href: [
					{ url: 'https://github.com/GabrielWeinbrenner/Chess-in-Vision-Pro', text: 'GitHub', icon: 'FaGithub' },
					{ url: 'https://x.com/cocog213/status/1750250315343900947', text: 'Demo', icon: 'FaRegPlayCircle' },
				],
				image: 'chess_vision.png',
				extraDescription: 'This project highlights AI integration and advanced graphics rendering.',
				tags: [{ text: 'AI' }, { text: 'Game Development' }, { text: 'VisionOS' }, { text: 'Swift' }],
				date: new Date('2024-01-01'),
			},
			{
				type: 'showcase',
				title: 'Procedural Sandbox',
				description:
					'A project focused on procedural content generation in VisionOS, creating dynamic and unique environments.',
				href: [
					{ url: 'https://github.com/GabrielWeinbrenner/Procedural-Sandbox', text: 'GitHub', icon: 'FaGithub' },
					{ url: 'https://x.com/cocog213/status/1781027414677594175', text: 'Demo', icon: 'FaRegPlayCircle' },
				],
				image: 'procedural_generation.png',
				extraDescription:
					'Explore how procedural generation can be used to create endless possibilities in virtual worlds.',
				tags: [{ text: 'Procedural Generation' }, { text: 'VisionOS' }, { text: 'Swift' }],
				date: new Date('2024-05-01'),
			},
			{
				type: 'showcase',
				title: 'Blackjack in C++',
				description:
					'Blackjack game implemented using SDL2, SDL2_image, and SDL2_ttf libraries',
				href: [
					{ url: 'https://github.com/GabrielWeinbrenner/Blackjack-in-C', text: 'GitHub', icon: 'FaGithub' },
				],
				image: 'blackjack.gif',
				extraDescription:
					'Test your luck and strategy against the dealer!',
				tags: [{ text: 'C++' }, { text: 'Graphics Programming' }],
				date: new Date('2024-07-01')
			}
		],
		footer: 'Visit my GitHub for more projects.',
	},
	contact: {
		title: 'Contact',
		paragraphs: [
			{
				type: 'description',
				description: 'You can reach out to me through the following contact methods:',
			},
			{
				type: 'description',
				title: 'Email',
				description: 'Email me at <u>gabriel.weinbrenner@gmail.com</u>',
			},
			{
				type: 'description',
				title: 'LinkedIn',
				description: 'Connect with me on <u><a href="https://www.linkedin.com/in/gabriel-weinbrenner/">LinkedIn</a></u>.',
				href: [{ url: 'https://www.linkedin.com/in/gabriel-weinbrenner/', text: 'LinkedIn', icon: 'FaLinkedin' }],
			},
			{
				type: 'description',
				title: 'GitHub',
				description: 'Check out my projects on <u><a href="https://github.com/GabrielWeinbrenner/">GitHub</a></u>.',
				href: [{ url: 'https://github.com/GabrielWeinbrenner/', text: 'GitHub', icon: 'FaGithub' }],
			},
			{
				type: 'description',
				title: 'Twitter',
				description: 'Follow me on <u><a href="https://x.com/cocog213">Twitter</a></u>.',
				href: [{ url: 'https://x.com/cocog213', text: 'Twitter', icon: 'FaTwitter' }],
			},
			{
				type: 'carousel',
				title: 'Contacts',
				description: '',
				items: [
					{
						type: 'description',
						title: 'Email',
						description: '',
						href: [{ url: 'mailto:gabriel.weinbrenner@gmail.com', text: '', icon: 'FaEnvelope' }],
					},
					{
						type: 'description',
						title: 'LinkedIn',
						description: '',
						href: [{ url: 'https://www.linkedin.com/in/gabriel-weinbrenner/', text: '', icon: 'FaLinkedin' }],
					},
					{
						type: 'description',
						title: 'GitHub',
						description: '',
						href: [{ url: 'https://github.com/GabrielWeinbrenner/', text: '', icon: 'FaGithub' }],
					},
					{
						type: 'description',
						title: 'Twitter',
						description: '',
						href: [{ url: 'https://x.com/cocog213', text: '', icon: 'FaTwitter' }],
					},

				],
			}
		],
		footer: 'Looking forward to hearing from you!',
	},
}

export const progression = [
	ModalType.About,
	ModalType.Education,
	ModalType.Experience,
	ModalType.Projects,
	ModalType.Contact,
]
