import { FaArrowRight, FaArrowLeft, FaTimes, FaExpand, FaCompress } from 'react-icons/fa'
import { useTarget } from '../../TargetContext'
import { content, ModalType, progression, ParagraphContent } from '../../types'
import React, { useEffect, useState } from 'react'
import TagFilter from './TagFilter'
import ContentSection from './ContentSection'

interface ContentModalProps {
	modalType: ModalType
}

const ContentCard: React.FC<ContentModalProps> = ({ modalType }) => {
	const contentData = content[modalType]
	const { setCurrentModalType } = useTarget()
	const [selectedTags, setSelectedTags] = useState<string[]>([])
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

	const changeModalType = (next: boolean) => () => {
		const currentIndex = progression.indexOf(modalType)
		const nextIndex = next
			? (currentIndex + 1) % progression.length
			: (currentIndex - 1 + progression.length) % progression.length
		const nextModalType = progression[nextIndex]
		setCurrentModalType(nextModalType)
	}

	const closeModal = () => {
		setCurrentModalType(null)
	}

	const handleTagClick = (tag: string) => {
		if (selectedTags.includes(tag)) {
			setSelectedTags(selectedTags.filter((t) => t !== tag))
		} else {
			setSelectedTags([...selectedTags, tag])
		}
	}

	const handleClearTags = () => {
		setSelectedTags([])
	}

	const filteredParagraphs =
		selectedTags.length > 0
			? contentData.paragraphs.filter((paragraph) => paragraph.tags?.some((tag) => selectedTags.includes(tag.text)))
			: contentData.paragraphs

	const uniqueTags = Array.from(
		new Set(contentData.paragraphs.flatMap((paragraph) => paragraph.tags?.map((tag) => tag.text) ?? [])),
	)

	const sortParagraphs = (paragraphs: ParagraphContent[]) => {
		return paragraphs.sort((a, b) => {
			if (!a.date || !b.date) return 0
			return sortOrder === 'asc' ? a.date.getTime() - b.date.getTime() : b.date.getTime() - a.date.getTime()
		})
	}

	const sortedAndFilteredParagraphs = sortParagraphs(filteredParagraphs)

	useEffect(() => {
		if (modalType === ModalType.Projects || modalType === ModalType.Education) {
			setSelectedTags(['Featured'])
			setSortOrder('desc')
		} else {
			setSortOrder('asc')
			setSelectedTags([])
		}
	}, [modalType])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'ArrowRight') {
				changeModalType(true)()
			} else if (event.key === 'ArrowLeft') {
				changeModalType(false)()
			} else if (event.key === 'Escape') {
				closeModal()
			} else if (event.key === 'f') {
				toggleFullScreen()
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [modalType])

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen)
	}

	return (
		<>
			<div className="card-container">
				<div className={`card bg-base-100 shadow-xl ${isFullScreen ? 'w-full' : 'w-5/12'}`}>
					<div className="card-body">
						<div className="flex justify-between items-left">
							<h2 className="card-title flex-grow text-center">{contentData.title}</h2>
							<div className="flex items-center">
								<div className="lg:tooltip lg:tooltip-bottom" data-tip="Fullscreen">
									<button className="btn btn-outline btn-sm mr-2" onClick={toggleFullScreen}>
										{isFullScreen ? <FaCompress /> : <FaExpand />}
									</button>
								</div>
								<div className="lg:tooltip lg:tooltip-bottom" data-tip="Close">
									<button className="btn btn-outline btn-sm" onClick={closeModal}>
										<FaTimes />
									</button>
								</div>
							</div>
						</div>
						{(modalType === ModalType.Projects || modalType === ModalType.Education) && (
							<div className="flex flex-col lg:flex-row justify-between items-center mb-4">
								<TagFilter
									uniqueTags={uniqueTags}
									selectedTags={selectedTags}
									onTagClick={handleTagClick}
									onClearTags={handleClearTags}
									sortFunctions={{
										'Date Made (ascending)': () => {
											setSortOrder('asc')
										},
										'Date Made (descending)': () => {
											setSortOrder('desc')
										},
										'Featured': () => {
											setSelectedTags(['Featured'])
										},
									}}
								/>
							</div>
						)}
						<div className="scrollable-paragraphs">
							{sortedAndFilteredParagraphs.map((paragraph, index) => (
								<ContentSection key={index} paragraph={paragraph} />
							))}
						</div>
						<div className="card-actions justify-end">
							<div className="lg:tooltip" data-tip="Previous">
								<button className="btn btn-outline btn-sm" onClick={changeModalType(false)}>
									<FaArrowLeft />
								</button>
							</div>

							<div className="lg:tooltip" data-tip="Next">
								<button className="btn btn-outline btn-sm" onClick={changeModalType(true)}>
									<FaArrowRight />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ContentCard
