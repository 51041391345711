import React, { useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom'
import { Canvas } from '@react-three/fiber'
import { Vector3 } from 'three'
import Experience from './Experience'
import { Settings, ModalType, ControlType } from '../types'
import { useTarget, TargetProvider } from '../TargetContext'
import ContentCard from './CardContent/ContentCard'
import HelperText from './TextRenders/HelperText'

const App: React.FC = () => {
	return (
		<Router>
			<TargetProvider>
				<MainApp />
			</TargetProvider>
		</Router>
	)
}

const parseModalType = (path: string): ModalType | null => {
	const formattedPath = path
	if (Object.values(ModalType).includes(formattedPath as ModalType)) {
		return formattedPath as ModalType
	}
	return null
}

const MainApp: React.FC = () => {
	const defaultSettings: Settings = {
		controlType: ControlType.Default,
		animations: true,
		lighting: true,
		sound: true,
		showPerformance: false,
	}

	const { currentModalType, setCurrentModalType } = useTarget()
	const [settings, _] = useState<Settings>(defaultSettings)
	const [showHelperText, setShowHelperText] = useState<boolean>(false)
	const [cameraPosition, setCameraPosition] = useState<Vector3>(new Vector3())
	const [introState, setIntroState] = useState<boolean>(true)
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const handleUserInteraction = () => {
			setShowHelperText(false)
			setIntroState(false)
			window.removeEventListener('click', handleUserInteraction)
		}
		window.addEventListener('click', handleUserInteraction)
		return () => {
			window.removeEventListener('click', handleUserInteraction)
		}
	}, [])

	useEffect(() => {
		if (currentModalType) {
			const path = `/${currentModalType.toLowerCase()}`
			if (location.pathname !== path) {
				navigate(path, { replace: true })
			}
		} else {
			if (location.pathname !== '/') {
				navigate('/', { replace: true })
			}
		}
	}, [currentModalType, navigate, location.pathname])

	useEffect(() => {
		const path = location.pathname.substring(1)
		const modalType = parseModalType(path)
		if (modalType) {
			setCurrentModalType(modalType)
			setShowHelperText(false)
			setIntroState(false)
		}
	}, [location.pathname, setCurrentModalType])

	useEffect(() => {
		if (cameraPosition.x !== 0 && cameraPosition.x <= 35 && introState) {
			setShowHelperText(true)
		} else {
			setShowHelperText(false)
		}
		return () => {
			setShowHelperText(false)
		}
	}, [cameraPosition, introState])

	return (
		<>
			<div className="navbar">
				<div className="navbar-start">
					<div className="dropdown">
						<div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
							</svg>
						</div>
						<ul
							tabIndex={0}
							className="menu menu-sm dropdown-content mt-3 z-[3] p-2 shadow bg-base-100 rounded-box w-52"
						>
							<li>
								<a
									onClick={() => {
										setCurrentModalType(ModalType.About)
									}}
								>
									About
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										setCurrentModalType(ModalType.Projects)
									}}
								>
									Projects
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										setCurrentModalType(ModalType.Contact)
									}}
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
					<button className="btn btn-ghost text-xl">Gabe's World</button>
				</div>
				<div className="navbar-end hidden lg:flex">
					<NavLink modalType={ModalType.About}>About</NavLink>
					<NavLink modalType={ModalType.Projects}>Projects</NavLink>
					<NavLink modalType={ModalType.Contact}>Contact</NavLink>
				</div>
			</div>
			<Suspense
				fallback={
					<div className="loading-container">
						<span className="loading loading-infinity loading-lg"></span>
					</div>
				}
			>
				<Canvas
					shadows
					camera={{
						fov: 40,
						near: 0.1,
						far: 100,
						position: [50, 0, 0],
					}}
				>
					<Experience settings={settings} onCameraMove={setCameraPosition} helperTextShown={showHelperText} />
				</Canvas>
			</Suspense>
			{currentModalType ? <ContentCard modalType={currentModalType} /> : null}
			<HelperText show={showHelperText} />
		</>
	)
}

const NavLink: React.FC<{ modalType: ModalType; children: React.ReactNode }> = ({ modalType, children }) => {
	const { setCurrentModalType } = useTarget()
	return (
		<button
			className="btn btn-ghost"
			onClick={() => {
				setCurrentModalType(modalType)
			}}
		>
			{children}
		</button>
	)
}

export default App
